import React from 'react';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IButtonRound {
    icon: IconDefinition;
    onClick: () => void;
    clean?: boolean;
    spin?: boolean;
    testId?: string;
}

export const ButtonRound: React.FC<IButtonRound> = (props) => {
    const { icon, onClick, clean, spin, testId } = props;
    return (
        <>
            <button
                data-test-id={testId}
                className={`relative flex h-8 w-8 items-center justify-center rounded-full p-2 opacity-100 transition hover:bg-amber-100 ${
                    spin ? 'animate-spin' : ''
                } ${clean ? 'bg-transparent' : 'bg-neutral-500'} `}
                onClick={() => onClick()}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className={`${clean ? 'text-neutral-500' : 'text-white'} h-4`}
                />
            </button>
        </>
    );
};

ButtonRound.defaultProps = {
    clean: false,
    spin: false,
};
